// gestion de modales, inspiré de https://sabe.io/tutorials/how-to-create-modal-popup-box

import type { TimeoutId } from 'src/lib/types'
import { setStyle } from 'src/lib/utils/css'
import { Elt, EltOptions, TagName } from 'src/lib/utils/dom/dom'
import { addElement, empty } from 'src/lib/utils/dom/main'
import './modal.scss'

export interface ModalOptions {
  title?:string
  inset?: string
  maxWidth?: string
  maxHeight?: string
  zIndex?: number | string
  fullScreen?: boolean
  closeWithOk?: boolean
  delay?: number
  onClose?: () => void
}

class Modal {
  isDestroyed: boolean
  /* le div en absolute qui prend toute la place */
  readonly #divWrapper: HTMLDivElement
  /* le div en absolute contenant la modale et ses bouton */
  readonly #divMain: HTMLDivElement
  readonly #header: HTMLElement
  /* le div en absolute sous titre et boutons qui aura l'éventuelle barre de scroll */
  readonly #divContent: HTMLDivElement
  readonly timeOutId: undefined | TimeoutId
  readonly onClose?: () => void

  /**
   * Un objet pour afficher/modifier/détruire une fenêtre modale
   * Exemple
   * ```js
   * // pour une modale quasi plein écran (20px de grisé autour laissant légèrement voir le body dessous)
   * const modal = new Modal({ title: 'Un joli titre', inset: '20px' })
   * const main = modal.addElement('div')
   * // addElement(main, 'p', …)
   * modal.show()
   * // et pour la détruire via du code (sinon faut cliquer sur la croix)
   * modal.destroy()
   *
   * // et pour une modale plus petite qui s'adapte au contenu (toujours centrée)
   * const modal = new Modal()
   * // ensuite idem
   * ```
   * @param [options] Si fourni avec top et left, la modale s’adaptera au contenu, avec seulement son coin supérieur gauche positionné (sinon elle prend tout l’écran)
   * @param [options.inset]
   * @param [options.zIndex] 50 par défaut, passer autre chose si besoin
   * @param [options.fullScreen] Passer true pour ajouter un bouton fullscreen
   * @param [options.closeWithOk] Passer true pour remplacer la croix par un bouton OK
   * @param [options.delay] Passer le nombre de secondes après laquelle la modale se fermera automatiquement
   */
  constructor ({ title, inset, maxWidth, maxHeight, zIndex, fullScreen, closeWithOk, delay, onClose }: ModalOptions = {}) {
    this.isDestroyed = false
    this.#divWrapper = addElement(document.body, 'div', { className: 'modalContainer' }) as HTMLDivElement
    if (zIndex) {
      this.#divWrapper.style.zIndex = String(zIndex)
    }
    // lui aussi est privé car il ne faudrait pas que l’appelant fasse du innerHTML += contenu, ça casserait notre listener sur le closeButton
    this.#divMain = addElement(this.#divWrapper, 'div', { className: 'modalMain' })
    // header pour titre + boutons
    this.#header = addElement(this.#divMain, 'header')
    // titre
    addElement(this.#header, 'h4', { content: title ?? '' })
    // bouton(s) fullscreen et fermeture
    if (fullScreen) {
      this.#addFullScreenBtn()
    }
    if (closeWithOk) {
      this.#addOkBtn()
    } else {
      this.#addCloseBtn()
    }
    if (inset == null) {
      if (maxWidth != null || maxHeight != null) {
        setStyle(this.#divMain, { maxWidth, maxHeight })
      }
    } else {
      // on centre en précisant les 4 bords (inset est relativement récent)
      setStyle(this.#divMain, { position: 'absolute', top: inset, left: inset, bottom: inset, right: inset, maxWidth, maxHeight })
    }
    if (maxWidth) {
      if (delay) {
        if (delay > 1000) {
          delay = delay / 1000
          console.error(Error('Il faut passer delay en secondes, pas ms'))
        }
        this.timeOutId = setTimeout(this.destroy.bind(this), delay)
      }
    }
    this.#divContent = addElement(this.#divMain, 'div', { style: { position: 'relative', left: '0', bottom: '0', right: '0', overflow: 'auto' } })
    if (typeof onClose === 'function') this.onClose = onClose
  }

  /**
   * À utiliser pour mettre du contenu dans la modale
   * @param tag
   * @param options
   */
  addElement<Tag extends TagName> (tag: Tag, options?: EltOptions<Tag>): Elt<Tag> {
    return addElement(this.#divContent, tag, options)
  }

  /**
   * Vide tout le contenu de la modale
   */
  empty (): void {
    if (this.isDestroyed) throw Error('La modale est détruite, impossible d’y ajouter du contenu')
    empty(this.#divMain)
    this.#addCloseBtn()
  }

  destroy (): void {
    clearTimeout(this.timeOutId)
    if (this.isDestroyed) return // console.error('La modale est déjà détruite')
    this.isDestroyed = true
    this.#divWrapper.parentNode?.removeChild(this.#divWrapper)
    if (this.onClose) this.onClose()
  }

  hide (): void {
    this.#divWrapper.classList.remove('showModal')
  }

  show (): void {
    this.#divWrapper.classList.add('showModal')
  }

  toggle (): void {
    this.#divWrapper.classList.toggle('showModal')
  }

  #addCloseBtn (): void {
    const btn = addElement(this.#header, 'span', { className: 'closeButton', content: '×' })
    btn.addEventListener('click', this.destroy.bind(this))
  }

  #addFullScreenBtn () {
    const btn = addElement(this.#header, 'span', { className: 'closeButton fullscreenButton', content: '▢' })
    btn.addEventListener('click', this.#toggleFullscreen.bind(this))
  }

  #addOkBtn () {
    const btn = addElement(this.#header, 'span', { className: 'closeButton', content: 'OK' })
    btn.addEventListener('click',
      this.destroy.bind(this))
  }

  #toggleFullscreen () {
    if (!document.fullscreenElement) {
      this.#divMain.requestFullscreen().catch(error => console.error(error))
    } else {
      document.exitFullscreen().catch(error => console.error(error))
    }
  }
}

export default Modal
